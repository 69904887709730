import React from 'react'

const Footer = ({ siteTitle }) => (

<footer>
  <p>+370 68 770999 | <a href="mailto:hello@peko.lt">hello@peko.lt</a></p>
</footer>

)

export default Footer
